import axios from 'axios';
import { handleResponse, handleError } from '../utilities/Response';
import { apiProvider } from '../utilities/Provider';

export class ApiDocument {
    constructor() {
        this.search = async (queryParams) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/document/getDocuments`, queryParams, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.save = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/document/add`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.sign = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/document/sign`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.send = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/document/sendDocumentByModel`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.contacts = async () =>{
            return await axios
                .get(`${apiProvider.BASE_URL}/api/document/getContacts`, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.clickStar = async (id) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/document/clickStar`, {id:id}, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.downloadFile = async (name) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/document/filebase64Name`, {fileName:name}, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.uploadFile = async (file) =>{
            var fd = new FormData();
            fd.append('file', file);
            return await axios
                .post(`${apiProvider.BASE_URL}/api/file/upload`, fd, {headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }})
        }

        this.get = async (id) =>{
            return await axios
                .get(`${apiProvider.BASE_URL}/api/document/getDocument?id=${id}`, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.openUrl = (resource) => {
            return fetch(`${apiProvider.BASE_URL}/${resource}`, apiProvider.config)
                .then((response)=>response.blob())
                .then((blob) => {
                    var _url = window.URL.createObjectURL(blob);
                    window.open(_url, "_blank").focus();
                })
                .catch(handleError);
        };

        this.saveContact = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/document/saveContact`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.removeContact = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/document/removeContact`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }
    }
}

export const apiDocument = new ApiDocument({});