import React, { useState, useEffect } from 'react';
import { Button,Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const PayLoadComponent = (props) => {
    const { register,  getValues, setValue } = useForm();
    const { t, i18n, ready } = useTranslation();
    
    useEffect(() => {
            
    }, []);

    return (
        <div>
            
            
        </div>
    );
}

export default PayLoadComponent;