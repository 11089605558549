import React, { useState, useEffect,useRef  } from 'react';
import { Button,Grid,  RadioGroup,Radio,FormControlLabel } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack5';
import {apiDocument} from '../../services/api/documents/Document';  
import { apiProvider } from '../../services/api/utilities/Provider';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Draggable, {DraggableCore} from 'react-draggable';
import SignatureCanvas from 'react-signature-canvas';
import {isMobile,useMobileOrientation } from 'react-device-detect';
import { PersonRemoveAlt1Outlined } from '@mui/icons-material';
import {authVerify} from "../../services/api/utilities/AuthVerify";
import { useTranslation } from "react-i18next";

const SignComponent = (props) => {
    const { register, handleSubmit, reset, getValues } = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [isBlur, setIsBlur] = useState(false);
    const [isClickSign, setIsClickSign] = useState(false);
    const [isNoSign, setIsNoSign] = useState(false);
    const [isTheirUser, setIsTheirUser] = useState(false);
    const [model, setModel] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState(1);
    const [showMoreIndex, setShowMoreIndex] = useState(-1);
    const [fileName, setFileName] = useState('');
    const [items, setItems] = useState([]);
    const [myItems, setMyItems] = useState([]);
    const [theirItems, setTheirItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [openSign, setOpenSign] = useState(false);
    const [openChangeLandscape, setOpenChangeLandscape] = useState(false);
    const [openPermitedDocument, setOpenPermitedDocument] = useState(false);
    const [searchParams] = useSearchParams();
    const [sigCanvas,setSigCanvas] = useState({});
    const [sigCanvasMobile,setSigCanvasMobile] = useState({});
    const [sigCanvasMobileLandscape,setSigCanvasMobileLandscape] = useState({});
    const [signNumbers, setSignNumbers] = useState(0);
    const [signedNumbers, setSignedNumbers] = useState(0);
    const {isLandscape} = useMobileOrientation();
    const [isLoading, setIsLoading] = useState(false);
    const [wasSend, setWasSend] = useState(false);
    const [isLoadingBlue, setIsLoadingBlue] = useState(true);
    const { t, i18n, ready } = useTranslation();

    const send = async () => {
        if(!isLoading)
        {
            setIsLoading(true);
            let document = {name: props.model.name, number: props.model.number, fileName: props.file.fileName, signOrder: props.model.signOrder, signMyOrder: props.model.signMyOrder, signTheirOrder: props.model.signTheirOrder, isAllPage: props.model.isAllPage, isFreeSort: true, isMarkers: props.model.isMarkers};
            document.items = [];
            for (let i = 0 ; i < myItems.length; i++) {
                document.items.push({number: (i+1).toString(),firstName: myItems[i].firstName, lastName: myItems[i].lastName, email: myItems[i].email, companyName: myItems[i].companyName, companyNumber: myItems[i].companyNumber});
                document.items[document.items.length-1].signs = [];
                let signs = items.filter(d=>d.email==myItems[i].email);
                for(let y=0;y<signs.length;y++)
                {
                    document.items[document.items.length-1].signs.push({x:signs[y].x, y: signs[y].y, width:220, height: 60, page: signs[y].page});
                }
                //document.items[document.items.length-1].signs.push({x: , y: });
            }
            for (let i = 0 ; i < theirItems.length; i++) {
                document.items.push({number: (i+1).toString(),firstName: theirItems[i].firstName, lastName: theirItems[i].lastName, email: theirItems[i].email, companyName: theirItems[i].companyName, companyNumber: theirItems[i].companyNumber, isTheir: true});
                document.items[document.items.length-1].signs = [];
                let signs = items.filter(d=>d.email==theirItems[i].email);
                for(let y=0;y<signs.length;y++)
                {
                    document.items[document.items.length-1].signs.push({x:signs[y].x, y: signs[y].y, width:220, height: 60, page: signs[y].page});
                }
                //document.items[document.items.length-1].signs.push({x: , y: });
            }
            console.log(document);
            let savedDocument = await apiDocument.save(document);
            if(savedDocument.id!==null)
            {
                document.id = savedDocument.id;
                let sendedDocument = await apiDocument.send(document);
                if(sendedDocument!=null)
                {
                    window.location.href = "/index";
                }
            }
        }
    }

    const signproc = async() => {
        if(!model.isAllPage)
            window.location.href = "/index";
        else
        {
            let signed = signedNumbers+1;
            setSignedNumbers(signed);
            console.log(signed+' '+signNumbers);
            if(signed>=signNumbers)
            {
                window.location.href = "/index";
            }
            else
            {
                if(isMobile && isLandscape)
                    sigCanvasMobileLandscape.clear();
                else if(isMobile)
                    sigCanvasMobile.clear();
                else
                    sigCanvas.clear();
            }

        }
    }

    const sign = async () => {
        if(!isLoading)
        {
            setIsLoading(true);
            if(isMobile && isLandscape)
            {
                let result = await apiDocument.sign({ id: model.id, signature: sigCanvasMobileLandscape.toDataURL(), number: signedNumbers });
                if(result!=null)
                {
                    signproc();
                    setIsLoading(false);
                    setWasSend(true);
                }
            }
            else if(isMobile)
            {
                let result = await apiDocument.sign({ id: model.id, signature: sigCanvasMobile.toDataURL(), number: signedNumbers });
                if(result!=null)
                {
                    signproc();
                    setIsLoading(false);
                    setWasSend(true);
                }
            }
            else
            {
                let result = await apiDocument.sign({ id: model.id, signature: sigCanvas.toDataURL(), number: signedNumbers });
                if(result!=null)
                {
                    signproc();
                    setIsLoading(false);
                    setWasSend(true);
                }
            }
        }
    }

    const downloadFile = () => {
        apiDocument.openUrl(`/api/document/file/${model.id}`);
    };

    const downloadSummaryFile = () => {
        window.open(`${apiProvider.BASE_URL}${model.summaryURL}`, '_blank');
    };

    const handleClickOpen = () => {
        if(props.model!==null && !props.model.isMarkers)
        {
            for (let i = 0 ; i < myItems.length; i++) {
                let signs = items.filter(d=>d.email==myItems[i].email);
                if(signs.length<=0)
                {
                    setIsBlur(true);
                    setIsNoSign(true);
                    return;
                }
            }
            for (let i = 0 ; i < theirItems.length; i++) {
                let signs = items.filter(d=>d.email==theirItems[i].email);
                if(signs.length<=0)
                {
                    setIsBlur(true);
                    setIsNoSign(true);
                    return;
                }
            }
        }
        setOpen(true);
        setIsBlur(true);
    };

    const handleClose = () => {
        setIsClickSign(false);
        setOpen(false);
        setOpenSign(false);
        setIsBlur(false);
    };

    const handleClear = () => {
        setIsClickSign(false);
        if(isMobile && isLandscape)
            sigCanvasMobileLandscape.clear();
        else if(isMobile)
            sigCanvasMobile.clear();
        else
            sigCanvas.clear();
    };

    const onDocumentLoadSuccess = ({ numPages }) =>  {
        setIsLoadingBlue(false);
        setNumPages(numPages);
    }

    const setSign = (row, index) => {        
        if((model===null || model.id<=0) && props.model!==null && !props.model.isMarkers)
        {
            items.push({email: row.email, firstName: row.firstName, lastName: row.lastName, companyName: row.companyName, companyNumber: row.companyNumber, y:0, x:0, index: index, page: pageNumber});
            setItems([...items]);
        }
    }

    const removeAll = (index) => {
        for(let i=0;i<items.length;i++)
        {
            if(items[i].index===index)
            {
                items.splice(i, 1);
                i--;
                continue;
            }
        }
        setItems([...items]);
    }

    const removeSign = (row) => {
        let index = items.indexOf(row);
        items.splice(index, 1);
        setItems([...items]);
    }

    const copySign = (row) => {
        let index = items.indexOf(row);
        for(let i=pageNumber;i<numPages+1;i++)
        {
            if(pageNumber!==i)
                items.push({email: row.email, firstName: row.firstName, lastName: row.lastName, companyName: row.companyName, companyNumber: row.companyNumber, y:row.y, x:row.x, index: row.index, page: i});
        }
        setItems([...items]);
    }

    const handleStop = (e,data,row) => {
        console.log(data);
        let index = items.indexOf(row);
        items[index].x = parseInt(data.x);
        items[index].y = parseInt(data.y);
        console.log(items[index]);
        setItems([...items]);
    }

    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        if (location.search.indexOf('id=') >= 0) {
            let id = searchParams.get('id');
            localStorage.setItem('documentId', id);
        }
        authVerify.verifyUser();
        if(openSign && isMobile && isLandscape){
            if(!sigCanvasMobile.isEmpty())
            {
                setOpenSign(false);
                setOpenChangeLandscape(true);
            }
        }
        else if(openSign && isMobile && !isLandscape){
            if(!sigCanvasMobileLandscape.isEmpty())
            {
                setOpenSign(false);
                setOpenChangeLandscape(true);
            }
        }
    }, [isLandscape])

    useEffect(() => {
        
        window.onpageshow = function(event) {
            if (event.persisted) {
                window.location.reload();
            }
        };

        async function fetchData() {
            if (location.search.indexOf('id=') >= 0) {
                if(wasSend)
                {
                    window.location.reload();
                    return;   
                }
                let document = await apiDocument.get(searchParams.get('id'));
                if(document!==null && document.perimited)
                {
                    setOpenPermitedDocument(true);
                    setIsLoading(false);
                    return;
                }

                if(document!==null)
                {
                    setModel({id:document.id, name: document.name, sendDate: document.sendDateName, summaryURL: document.summaryURL, isToSigned: document.isToSigned, isMySigned: document.isMySigned, isMy: document.isMy, isAllPage: document.isAllPage, signOrder:document.signOrder});
                    if(document.items!==null)
                    {
                        setMyItems(document.items.filter(d=>!d.isTheir));
                        setTheirItems(document.items.filter(d=>d.isTheir));
                        let email = localStorage.getItem('email');
                        setIsTheirUser(document.items.filter(d=>d.isTheir && d.email==email).length>0);
                        items.length=0;
                        (document.signItems).forEach(element => {
                            if(!element.item.isSigned)
                            {
                                let itemsToSigns =document.items.filter(d=>!d.isTheir);
                                
                                let index = itemsToSigns.indexOf(itemsToSigns.filter(d=>d.email===element.item.email)[0]);
                                if(index<0)
                                {
                                    itemsToSigns =document.items.filter(d=>d.isTheir);
                                    index = itemsToSigns.indexOf(itemsToSigns.filter(d=>d.email===element.item.email)[0])+4;
                                }
                                items.push({email: element.item.email, firstName: element.item.firstName, lastName: element.item.lastName, companyName: element.item.companyName, companyNumber: element.item.companyNumber, y:element.y, x:element.x, index: index, page: element.page});
                            }
                        });
                        setItems([...items]);
                    }
                    let file = await apiDocument.downloadFile(document.fileURL);
                    if(file!==null && file.file64!==null)
                    {
                        setFile('data:application/pdf;base64,'+file.file64);
                    }
                    setFileName(document.fileURL);
                    if(document.isToSigned>0 && !document.isMySigned && document.isAllPage)
                    {
                        let email = localStorage.getItem('email');
                        setSignNumbers(document.signItems.filter(d=>d.item.email===email).length);
                    }
                }
            }
            else
            {
                let file = await apiDocument.downloadFile(props.file.fileName);
                setFile('data:application/pdf;base64,'+file.file64);
                setFileName(props.file.fileName);
                if(props!==null && props.myItems!==null)
                {
                    setMyItems(props.myItems);
                    setTheirItems(props.theirItems);
                }
            }
        }
        fetchData();
    }, []);

    const handleMySort = (e, data, row) => {
		//duplicate items
		let _myItems = [...myItems]

		//remove and save the dragged item content
		const draggedItemContent = _myItems.splice(dragItem.current, 1)[0]

		//switch the position
		_myItems.splice(dragOverItem.current, 0, draggedItemContent)

		//reset the position ref
		dragItem.current = null
		dragOverItem.current = null

		//update the actual array
		setMyItems(_myItems)
	}

    const handleTheirSort = (e, data, row) => {
		//duplicate items
		let _theirItems = [...theirItems]

		//remove and save the dragged item content
		const draggedItemContent = _theirItems.splice(dragItem.current, 1)[0]

		//switch the position
		_theirItems.splice(dragOverItem.current, 0, draggedItemContent)

		//reset the position ref
		dragItem.current = null
		dragOverItem.current = null

		//update the actual array
		setTheirItems(_theirItems)
	}

    const setIsAllPage = (value) => {
        props.model.isAllPage = value==="1";
    }

    const dragItem = React.useRef(null)
	const dragOverItem = React.useRef(null)

    const logOff = () =>{
        localStorage.setItem('token', '');
        localStorage.setItem('fullName', '');
        localStorage.setItem('role', '');
        localStorage.setItem('email', '');
        localStorage.setItem('avatar', '');
        window.location.href = "/";
    }

    return (
        <div>
            {isNoSign && 
                <div className="popup">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <h5>Ustaw miejsce podpisów dla wszystkich podpisujących</h5>
                                </Grid>
                                <Grid item xs={1} className="right-element">
                                    <a className="x" onClick={()=>{setIsNoSign(false);setIsBlur(false);}}></a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={()=>{setIsNoSign(false);setIsBlur(false);}}>Zamknij</Button>
                        </Grid>
                    </Grid>
                </div>
            }
            <div className={`${!isLoadingBlue ? "not-show":''}`}>
                <Grid container className="padding-body">
                    <div className="loader-blue"></div>
                </Grid>
            </div>
            <div className={`main-body sign-body ${ isBlur ? "blur":''} ${isLoadingBlue ? "not-show":''}`}>
                <Grid container>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        {model!==null && <h2>{t("sign_text1")}</h2>}
                        {model===null && <h2>{t("sign_text2")}</h2>}
                    </Grid>
                </Grid>
                <Grid container className="border-grid">
                    <Grid item xs={6} className="border-first">
                        <div>
                            <div className="sign-box">
                                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber} width={484}/>
                                </Document>
                                {items.map((row, index) => {
                                return(row.page===pageNumber && !isLoadingBlue && <Draggable position={{x:row.x, y: row.y}} 
                                    bounds="parent" disabled={location.search.indexOf('id=') >= 0} onStop={(e,data)=>handleStop(e,data,row)}>
                                    <div className={`person-sign ${"person-sign"+(location.search.indexOf('id=') >= 0 ? "-back":"")+(row.index)}`}>
                                        {location.search.indexOf('id=') < 0 && 
                                            <div>
                                                <a className="copy" onClick={()=>copySign(row)}></a>
                                                <a className="remove" onClick={()=>removeSign(row)}></a>
                                            </div>
                                        }
                                        <span className="person-name">{row.firstName+' '+row.lastName}</span>
                                    </div>
                                </Draggable>);
                                })}
                            </div>
                            <Grid container className="center-page">
                                <Grid item style={{width:"30px"}}><a className='first' onClick={()=>setPageNumber(1)}></a></Grid>
                                <Grid item style={{width:"30px"}}><a className='prev' onClick={()=>setPageNumber(pageNumber-1 >=1 ? pageNumber-1 : 1)}></a></Grid>
                                <Grid item style={{width:"30px"}}><a className='next' onClick={()=>setPageNumber(pageNumber+1<=numPages ? pageNumber+1:numPages)}></a></Grid>
                                <Grid item style={{width:"30px"}}><a className='last' onClick={()=>setPageNumber(numPages)}></a></Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="border-second">
                        <div className="sign-box2">
                            <Grid container>
                                <Grid direction="row" xs className="signers-box">
                                    {model!==null && 
                                        <div>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <p className="sign-document-label">{t("sign_text3")}</p>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <p className="sign-document-label">{t("sign_text4")}</p>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <p className="sign-document-name">{model.sendDate}</p>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <p className="sign-document-name">{model.name}</p>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    {(model===null || model.id<=0) && props.model!=null && 
                                        <div>
                                            <Grid item xs={12}>
                                                <p className="sign-document-label">{t("sign_text5")}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className="sign-document-name">{props.model.name}</p>
                                            </Grid>
                                        </div>
                                    }
                                    {(model===null || model.id<=0) && props.model!=null && 
                                        <Grid container className="info">
                                            <Grid item style={{width:"30px"}}>
                                                <i className="info-svg"></i>
                                            </Grid>
                                            <Grid item xs>
                                                <p>{t("sign_text6b")}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {model!==null && !isTheirUser && 
                                        <div>
                                            <Grid item xs={12}>
                                                <p className="sign-document-label">{t("sign_text6")}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className="sign-document-name">{model!==undefined && model.signOrder===1 ? t("sign_text9"):(model!==undefined && model.signOrder===2 ? t("sign_text7"):t("sign_text8"))}</p>
                                            </Grid>
                                        </div>
                                    }
                                    {!isTheirUser &&
                                        <div>
                                            <Grid>
                                                <h4>{t("sign_text14b")}</h4>
                                            </Grid>
                                            {model!==null && 
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <p className="sign-document-label">{t("sign_text10")}</p>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <p className="sign-document-name">{t("sign_text11")}</p>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {myItems.map((row, index) => {
                                                return(<Grid item xs={12} className={`box-item ${"box-item"+index}`}>
                                                    {model===null && 
                                                        <Grid container draggable
                                                        onDragStart={(e) => (dragItem.current = index)}
                                                        onDragEnter={(e) => (dragOverItem.current = index)}
                                                        onDragEnd={handleMySort}
                                                        onDragOver={(e) => e.preventDefault()}>
                                                            <Grid item xs={1} onClick={()=>setSign(row, index)}>
                                                                <a className="edit-icon"></a>
                                                            </Grid>
                                                            <Grid item xs={9} onClick={()=>setSign(row, index)}>
                                                                <p className='box-item-margin'>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}<br/>{row.email}</p>
                                                            </Grid>
                                                            <Grid item xs={1} className="right-element" onClick={()=>setSign(row, index)}>
                                                                <p style={{marginTop:"12px"}}>({items.filter(d=>d.email==row.email).length})</p>
                                                            </Grid>
                                                            <Grid item xs className="right-element" style={{width:"20px"}} onClick={()=>setShowMoreIndex((index===showMoreIndex ? -1:index))}>
                                                                <a className="dots"></a>
                                                            </Grid>
                                                            {showMoreIndex===index && <Grid item xs={12} className="right-element">
                                                                    <a className='dot-href' onClick={()=>removeAll(index)}>{t("sign_text12")}</a>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    }
                                                    {model!==null && 
                                                        <Grid container>
                                                            <Grid item xs={8}>
                                                                <p className='box-item-margin'>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}<br/>{row.email}</p>
                                                            </Grid>
                                                            {row.signDateName!==null && row.signDateName!=='' && 
                                                                <Grid item xs={3}>
                                                                    <p className='box-item-margin center'>{t("sign_text13")}<br/>{row.signDateName}</p>
                                                                </Grid>
                                                            }
                                                            {(row.signDateName===null || row.signDateName==='') && 
                                                                <Grid item xs={3}>
                                                                    
                                                                </Grid>
                                                            }
                                                            <Grid item xs={1} className="right-element">
                                                                <a className="dots"></a>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>);
                                            })}
                                        </div>
                                    }
                                    {theirItems.length>0 && 
                                        <Grid>
                                            {!isTheirUser && <h4>{t("sign_text14")}</h4>}
                                            {isTheirUser && <h4>{t("sign_text14b")}</h4>}
                                        </Grid>
                                    }
                                    {theirItems.length>0 && 
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <p className="sign-document-label">{t("sign_text15")}:</p>
                                            </Grid>
                                            <Grid item xs>
                                                <p className="sign-document-name">{t("sign_text16")}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {theirItems.map((row, index) => {
                                        return (<Grid item xs={12} className={`box-item ${"box-item"+(index+4)}`}>
                                            {model===null && 
                                                <Grid container draggable
                                                onDragStart={(e) => (dragItem.current = index)}
                                                onDragEnter={(e) => (dragOverItem.current = index)}
                                                onDragEnd={handleTheirSort}
                                                onDragOver={(e) => e.preventDefault()}>
                                                    <Grid item xs={1} onClick={()=>setSign(row,index+4)}>
                                                        <a className="edit-icon"></a>
                                                    </Grid>
                                                    <Grid item xs={9} onClick={()=>setSign(row,index+4)}>
                                                        <p className='box-item-margin'>{row.firstName+' '+row.lastName+(row.companyName!=='' ? '-'+row.companyName:'')+'-'+row.companyNumber}<br/>{row.email}</p>
                                                    </Grid>
                                                    <Grid item xs={1} className="right-element" onClick={()=>setSign(row,index+4)}>
                                                        <p style={{marginTop:"12px"}}>({items.filter(d=>d.email==row.email).length})</p>
                                                    </Grid>
                                                    <Grid item xs className="right-element" style={{width:"20px"}} onClick={()=>setShowMoreIndex((index+4===showMoreIndex ? -1:(index+4)))}>
                                                        <a className="dots"></a>
                                                    </Grid>
                                                    {showMoreIndex===index+4 && <Grid item xs={12} className="right-element">
                                                            <a className='dot-href' onClick={()=>removeAll(index+4)}>{t("sign_text17")}</a>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                            {model!==null && 
                                                <Grid container>
                                                    <Grid item xs={8}>
                                                        <p className='box-item-margin'>{row.firstName+' '+row.lastName+row.lastName+(row.companyName!=='' ? '-'+row.companyName:'')+'-'+row.companyNumber}<br/>{row.email}</p>
                                                    </Grid>
                                                    {row.signDateName!==null && row.signDateName!=='' && 
                                                        <Grid item xs={3}>
                                                            <p className='box-item-margin center'>{t("sign_text18")}<br/>{row.signDateName}</p>
                                                        </Grid>
                                                    }
                                                    {(row.signDateName===null || row.signDateName==='') && 
                                                        <Grid item xs={3}>
                                                            
                                                        </Grid>
                                                    }
                                                    <Grid item xs={1} className="right-element">
                                                        <a className="dots"></a>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>);
                                    })}
                                    {model!==null && model.isMy && model.isMySigned && 
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <h4 className="center">{t("sign_text19")}</h4>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button className="button button-white center-item" full-width onClick={()=>downloadSummaryFile()}><i className="download-white-icon"></i>&nbsp;&nbsp;{t("sign_text20")}</Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    {model===null &&
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <p className="box-signers-radio-info">{t("sign_text21")}</p>
                                            </Grid>
                                            <Grid container>
                                                <RadioGroup className="radio" defaultValue={(props.model!==undefined && props.model.isAllPage ? "1":"2")} onChange={(event)=>setIsAllPage(event.target.value)}>
                                                    <FormControlLabel value="1" control={<Radio />} label={t("sign_text22")} />
                                                    <FormControlLabel value="2" control={<Radio />} label={t("sign_text23")} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item style={{height:"50px"}} xs={12} className="signers-box2">
                                    <Grid container spacing={2}>
                                        {model===null &&
                                            <Grid item xs={3}>
                                                <a onClick={()=>props.setIsSignComponent(false)} className="button button-cancel" fullWidth><i className="back"></i>{t("sign_text24")}</a>
                                            </Grid>
                                        }
                                        {model!==null &&
                                            <Grid item xs={3}>
                                                <a onClick={()=>navigate(-1)} className="button button-cancel" fullWidth><i className="back"></i>{t("sign_text25")}</a>
                                            </Grid>
                                        }
                                        {model===null &&
                                            <Grid item xs={9}>
                                                <Button className="button full-width" full-width onClick={()=>handleClickOpen()}>{t("sign_text26")}</Button>
                                            </Grid>
                                        }
                                        {model!==null && (model.isToSigned===0 || model.isMySigned) && 
                                            <Grid item xs={9}>
                                                <Button className="button full-width" full-width onClick={()=>downloadFile()}><i className="download-icon"></i>&nbsp;&nbsp;{t("sign_text27")}</Button>
                                            </Grid>
                                        }
                                        {model!==null && (model.isToSigned>0 && !model.isMySigned) && 
                                            <Grid item xs={9}>
                                                <Button className="button full-width signer" full-width onClick={()=>{setOpenSign(true);}}><i className="edit-sign-icon"></i>&nbsp;&nbsp;{t("sign_text28")}</Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Dialog open={open} onClose={handleClose} style={{padding:"10px"}}>
                <DialogContent>
                <DialogContentText>
                    {t("sign_text29")}
                </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={handleClose} className="button button-cancel" fullWidth><i className="back"></i>{t("sign_text30")}</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>send()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>{t("sign_text31")}</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={openChangeLandscape} onClose={handleClose} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                    {t("sign_text32b")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <Button className="button" onClick={()=>{setOpenSign(true);setOpenChangeLandscape(false);}}>
                                OK
                            </Button>
                        </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={openPermitedDocument} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                    {t("sign_text32c")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <Button className="button" onClick={()=>{logOff();}}>
                                Zaloguj się na inne konto
                            </Button>
                        </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={openSign} onClose={handleClose} style={{padding:"10px"}}>
                <div className="dialog-sign">
                    <DialogTitle>{t("sign_text32")}</DialogTitle>
                    <DialogContent>
                        {model!==null && model.isAllPage && <p className='sign-quantity'>{(signedNumbers+1)+'/'+signNumbers}</p>}
                        {!isMobile &&
                        <div className="not-mobile">
                            <SignatureCanvas canvasProps={{width: 500, height: 300, className: 'sigCanvas'}} ref={(ref) => { setSigCanvas(ref) }} onBegin={()=>setIsClickSign(true)}/>
                        </div>
                        }
                        {isMobile && !isLandscape &&
                        <div className="only-mobile">
                            <SignatureCanvas canvasProps={{width: 250, height: 250, className: 'sigCanvas'}} ref={(ref) => { setSigCanvasMobile(ref) }} onBegin={()=>setIsClickSign(true)}/>
                        </div>
                        }
                        {isMobile && isLandscape &&
                        <div className="only-mobile">
                            <SignatureCanvas canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} ref={(ref) => { setSigCanvasMobileLandscape(ref) }} onBegin={()=>setIsClickSign(true)}/>
                        </div>
                        }
                    </DialogContent>
                    <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <a className="button button-cancel" fullWidth onClick={()=>handleClose()}>{t("sign_text33")}</a>&nbsp;&nbsp;
                            <a className="button button-cancel" fullWidth onClick={()=>handleClear()}>{t("sign_text34")}</a>&nbsp;&nbsp;
                            <Button className="button" onClick={()=>sign()} disabled={!isClickSign}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>{t("sign_text35")}</span>
                                }
                            </Button>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default SignComponent;