import React, { useState, useEffect } from 'react';
import { Button,Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,TextField} from '@material-ui/core';
import { useForm } from "react-hook-form";
import {apiDocument} from '../../services/api/documents/Document';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from "react-i18next";

const ContactComponent = (props) => {
    const [items, setItems] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const { t, i18n, ready } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [id, setIdValue] = useState(-1);
    const [isEditMode, setEditMode] = useState(false);
    const { register,  getValues, setValue } = useForm();

    useEffect(() => {
        async function fetchData() {
            let contacts = await apiDocument.contacts();
            if(contacts!==null)
            {
                setItems(contacts.items);
            }
        }
        fetchData();
    }, []);

    const refreshItems = async () => {
        let contacts = await apiDocument.contacts();
        if(contacts!==null)
        {
            setItems(contacts.items);
        }
    }

    const removeUser = async () => {
        await apiDocument.removeContact({id: id});
        await refreshItems();
        setOpenDelete(false);
    };

    const showRemove = (id) => {
        setIdValue(id);
        setOpenDelete(true);
    };

    const handleClose = () => {
        props.setIsOpenContacts(false);
    };

    const setContact = (id,email,firstName, lastName, company, city) => {
        setIdValue(id);
        setValue("email",email);
        setValue("firstName",firstName);
        setValue("lastName",lastName);
        setValue("company",company);
        setValue("companyNumber",city);
        setEditMode(true);
    }

    const cancel = () => {
        setValue("email","");
        setValue("firstName","");
        setValue("lastName","");
        setValue("company","");
        setValue("companyNumber","");
        setEditMode(false);
    }

    const add = async () => {
        setEditMode(true);
        setIdValue(0);
        setValue("email","");
        setValue("firstName","");
        setValue("lastName","");
        setValue("company","");
        setValue("companyNumber","");
    }

    const addContact = async () => {
        var email = getValues("email");
        var firstName = getValues("firstName");
        var lastName = getValues("lastName");
        var company = getValues("company");
        var companyNumber = getValues("companyNumber");
        if(email===null || email==='')
        {
            setValidationMessage(t("users_text21"));
            return;   
        }
        if(email.indexOf('@')<0)
        {
            setValidationMessage(t("users_text22"));
            return;   
        }
        if(firstName===null || firstName==='')
        {
            setValidationMessage(t("users_text23"));
            return;   
        }
        if(lastName===null || lastName==='')
        {
            setValidationMessage(t("users_text24"));
            return;   
        }
        let contact = await apiDocument.saveContact({email:email, firstName: firstName, lastName: lastName, company: company, roleId: 2, id: id, companyNumber:companyNumber});
        if(contact!=null)
        {
            refreshItems();
            setEditMode(false);
        }
    }

    return (
        <div>
            <Dialog open={props.isOpenContacts} onClose={handleClose} style={{padding:"10px"}} maxWidth="lg" fullWidth>
                <div className='dialog-user'>
                    <DialogTitle>
                        {t("contact_text1")}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                    <Grid container className="right-side">
                            <Button className="button button-white" onClick={()=>add()}>{t("contact_text9")}</Button>
                        </Grid>
                        <br/>
                        <br/>
                    {isEditMode && 
                            <Grid container className="add-person-box">
                                <Grid item xs={12}>
                                    <h3>{id<=0 ? t("contact_text9"):t("contact_text8")}</h3>
                                </Grid>
                                {validationMessage && <Grid container>
                                    <div className="validation-error">{validationMessage}</div>
                                </Grid>
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField {...register("firstName", { required: true })} placeholder={t("users_text6")}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField {...register("lastName", { required: true })} placeholder={t("users_text7")}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField autoFocus {...register("email", { required: true })} placeholder={t("users_text4")}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField {...register("company", { required: true })} placeholder={t("users_text5")}/>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField {...register("companyNumber", { required: true })} placeholder={t("users_text5c")}/>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>addContact()} fullWidth>{t("users_text8")}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>cancel()} fullWidth style={{marginLeft:10}}>{t("users_text9")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {isEditMode && 
                            <br/>
                        }
                        <Grid>
                            <TableContainer className="document-list user user-list">
                                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">{t("contact_text2")}</TableCell>
                                            <TableCell align="left">{t("contact_text3")}</TableCell>
                                            <TableCell align="left">{t("contact_text4")}</TableCell>
                                            <TableCell align="left">{t("contact_text5")}</TableCell>
                                            <TableCell align="left">{t("contact_text6")}</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => {
                                            return (
                                            <TableRow key={index}>
                                                <TableCell align="left">{row.firstName}</TableCell>
                                                <TableCell align="left">{row.lastName}</TableCell>
                                                <TableCell align="left">{row.email}</TableCell>
                                                <TableCell align="left">{row.company}</TableCell>
                                                <TableCell align="left">{row.companyNumber}</TableCell>
                                                <TableCell align="left"><a className="x" onClick={()=>showRemove(row.id)}></a>&nbsp;&nbsp;&nbsp;&nbsp;<a className='user-list-edit' onClick={()=>setContact(row.id, row.email, row.firstName, row.lastName, row.company, row.companyNumber)}></a></TableCell>
                                            </TableRow>);
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <a className="button button-cancel" fullWidth onClick={()=>handleClose()}>{t("contact_text7")}</a>&nbsp;&nbsp;
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
            <Dialog open={openDelete} onClose={()=>setOpenDelete(false)} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                        Czy na pewno chcesz usunąć kontakt?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={()=>setOpenDelete(false)} className="button button-cancel" fullWidth><i className="back"></i>Nie</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>removeUser()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>Tak</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ContactComponent;