import React, { useState, useEffect, useRef } from 'react';
import { Button,Grid,  RadioGroup, TextField, FormLabel, Radio,FormControlLabel, Checkbox } from '@material-ui/core';
import { Link } from 'react-router-dom'; 
import { useForm } from "react-hook-form";
import useFileUpload from 'react-use-file-upload';
import SignComponent from '../sign/SignComponent';
import { apiDocument } from '../../services/api/documents/Document';
import { apiProvider } from '../../services/api/utilities/Provider';
import {authVerify} from "../../services/api/utilities/AuthVerify";
import { useTranslation } from "react-i18next";

const CreateComponent = () => {
    const { register,  getValues, setValue } = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [myItems, setMyItems] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [role, setRole] = useState('');
    const [searchMyContacts, setSearchMyContacts] = useState([]);
    const [searchTheirContacts, setSearchTheirContacts] = useState([]);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [theirItems, setTheirItems] = useState([]);
    const [isMarkers, setIsMarkers] = useState(false);
    const [markers, setMarkers] = useState(0);
    const [isAddMyItem, setAddIsMyItem] = useState(false);
    const [isAddTheirItem, setAddIsTheirItem] = useState(false);
    const [isSearchMyItem, setSearchIsMyItem] = useState(false);
    const [isSignComponent, setIsSignComponent] = useState(false);
    const [isSearchTheirItem, setSearchIsTheirItem] = useState(false);
    const [model, setModel] = useState({items:[], theirItems:[]});
    const [classButton1, setClassButton1] = useState('button-sign');
    const [classButton2, setClassButton2] = useState('button-sign');
    const [classButton3, setClassButton3] = useState('button-sign');
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n, ready } = useTranslation();

    useEffect(() => {
        authVerify.verifyUser();
        setClassButton2('button-sign-active');

        async function fetchData() {
            let items  = await apiDocument.contacts();
            setContacts(items.items);
            setSearchMyContacts(items.items.filter(d=>!d.isTheir).slice(0, 5));
            setSearchTheirContacts(items.items.filter(d=>d.isTheir).slice(0, 5));
        }

        fetchData();

        setRole(localStorage.getItem('role'));
    }, []);
    
    const {
        handleDragDropEvent,
      } = useFileUpload();

    const inputRef = useRef();

    const mySearch = async(query) => {        
        setSearchMyContacts(contacts.filter(d=>!d.isTheir && (d.email.toLowerCase().indexOf(query.toLowerCase())>=0 || (d.firstName+' '+d.lastName)===null || (d.firstName+' '+d.lastName).toLowerCase().indexOf(query.toLowerCase())>=0 || d.company===null || d.company.toLowerCase().indexOf(query.toLowerCase())>=0)).slice(0, 5));
    }

    const theirSearch = async(query) => {        
        setSearchTheirContacts(contacts.filter(d=>d.isTheir && (d.email.toLowerCase().indexOf(query.toLowerCase())>=0 || (d.firstName+' '+d.lastName)==null || (d.firstName+' '+d.lastName).toLowerCase().indexOf(query.toLowerCase())>=0 || d.company===null || d.company.toLowerCase().indexOf(query.toLowerCase())>=0)).slice(0, 5));
    }

    const showPdf = () => {
        window.open(`${apiProvider.BASE_URL}${file.fileName}`,'_blank');
    };

    const setFiles = (a) => {
        let ev = a.target;
        if(a.dataTransfer!==null && a.dataTransfer!==undefined)
        {
            ev = a.dataTransfer;
        }
        apiDocument.uploadFile(ev.files[0]).then((file)=>{
            if(file!==null)
            {
                setValidationMessage('');
                if(file.data.error)
                {
                    setValidationMessage(t("create_text4c"));
                    return;
                }
                setFile(file.data);
                let oryginalFileName = file.data.oryginalFileName.replaceAll('"','');
                setFileName(oryginalFileName);
                setIsMarkers(file.data.isMarkers);
                setMarkers(file.data.markers);
                setValue('name',oryginalFileName);
            }
        });
    }

    const clickMyItem = (row) => {
        if(myItems.filter(d=>d.email==row.email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.email==row.email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }

        setSearchIsMyItem(false);
        myItems.push({email: row.email, firstName: row.firstName, lastName: row.lastName, companyName: row.company, companyNumber: row.companyNumber});
    }

    const clickTheirItem = (row) => {
        if(myItems.filter(d=>d.email==row.email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.email==row.email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }

        setSearchIsTheirItem(false);
        theirItems.push({email: row.email, firstName: row.firstName, lastName: row.lastName, companyName: row.company, companyNumber: row.companyNumber});
    }

    const addMyPerson = () => {
        var email = getValues("myEmail");
        var firstName = getValues("myFirstName");
        var lastName = getValues("myLastName");
        var myCompanyName = getValues("myCompanyName");
        var myCompanyNumber = getValues("myCompanyNumber");
        if(email===null || email==='')
        {
            setValidationMessage(t("create_text1"));
            return;   
        }
        if(email.indexOf('@')<0)
        {
            setValidationMessage(t("create_text2"));
            return;   
        }
        if(firstName===null || firstName==='')
        {
            setValidationMessage(t("create_text3"));
            return;   
        }
        if(lastName===null || lastName==='')
        {
            setValidationMessage(t("create_text4"));
            return;   
        }
        if(myItems.filter(d=>d.email==email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.email==email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        setValidationMessage('');
        myItems.push({email: email, firstName: firstName, lastName: lastName, companyName: myCompanyName, companyNumber: myCompanyNumber});
        setMyItems([...myItems]);
        setAddIsMyItem(false);
        setSearchIsMyItem(false);
        setValue('myEmail','');
        setValue('myFirstName','');
        setValue('myLastName','');
        setValue('myCompanyName','');
        setValue('myCompanyNumber','');
    }

    const addTheirPerson = () => {
        var email = getValues("theirEmail");
        var firstName = getValues("theirFirstName");
        var lastName = getValues("theirLastName");
        var myCompanyName = getValues("theirCompanyName");
        var myCompanyNumber = getValues("theirCompanyNumber");
        if(email===null || email==='')
        {
            setValidationMessage(t("create_text5"));
            return;   
        }
        if(email.indexOf('@')<0)
        {
            setValidationMessage(t("create_text6"));
            return;   
        }
        if(firstName===null || firstName==='')
        {
            setValidationMessage(t("create_text7"));
            return;   
        }
        if(lastName===null || lastName==='')
        {
            setValidationMessage(t("create_text8"));
            return;   
        }
        if(myItems.filter(d=>d.email==email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.email==email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        setValidationMessage('');
        theirItems.push({email: email, firstName: firstName, lastName: lastName, companyName: myCompanyName, companyNumber: myCompanyNumber});
        setTheirItems([...theirItems]);
        setAddIsTheirItem(false);
        setSearchIsTheirItem(false);
        setValue('theirEmail','');
        setValue('theirFirstName','');
        setValue('theirLastName','');
        setValue('theirCompanyName','');
        setValue('theirCompanyNumber','');
    }

    const addMyClick = () => {
        setAddIsMyItem(true);
    }

    const searchMyClick = () => {
        setSearchIsMyItem(true);
    }

    const searchTheirClick = () => {
        setSearchIsTheirItem(true);
    }

    const closeMyClick = () => {
        setValue('myEmail','');
        setValue('myFirstName','');
        setValue('myLastName','');
        setValue('myCompanyName','');
        setValue('myCompanyNumber','');
        setAddIsMyItem(false);
        setSearchIsMyItem(false);
    }

    const addTheirClick = () => {
        setAddIsTheirItem(true);
    }

    const closeTheirClick = () => {
        setValue('theirEmail','');
        setValue('theirFirstName','');
        setValue('theirLastName','');
        setValue('theirCompanyName','');
        setValue('theirCompanyNumber','');
        setAddIsTheirItem(false);
        setSearchIsTheirItem(false);
    }

    const removeTheirClick = (row) => {
        let index = theirItems.indexOf(row);
        theirItems.splice(index, 1);
        setTheirItems([...theirItems]);
    }

    const removeMyClick = (row) => {
        let index = myItems.indexOf(row);
        myItems.splice(index, 1);
        setMyItems([...myItems]);
    }

    const setSignOrder = (order) => {
        model.signOrder = order;
        setModel(model)
    }

    const setSignMyOrder = (order) => {
        model.signMyOrder = order!=="1";
        setModel(model)
    }

    const setSignTheirOrder = (order) => {
        model.signTheirOrder = order!=="1";
        setModel(model)
    }

    const submitDocument = () => 
    {   
        if(!isLoading)
        {
            setIsLoading(true);
            if(file===null)
            {
                setValidationMessage(t("create_text9"));
                setIsLoading(false);
                return;   
            }
            if(getValues("name")===null || getValues("name")==='')
            {
                setValidationMessage(t("create_text10"));
                setIsLoading(false);
                return;   
            }
            if(isMarkers && markers!==theirItems.length+myItems.length)
            {
                setValidationMessage(t("create_text10b"));
                setIsLoading(false);
                return;   
            }
            
            if(theirItems.length+myItems.length===0)
            {
                setValidationMessage(t("create_text11"));
                setIsLoading(false);
                return;   
            }
            model.name = getValues('name');
            
            if(!model.signOrder)
            {
                model.signOrder=2;
            }
            if(!model.signMyOrder)
            {
                model.signMyOrder=false;
            }
            if(!model.signTheirOrder)
            {
                model.signTheirOrder=false;
            }
            model.isMarkers = isMarkers;
            setModel(model);
            setIsLoading(false);
            setIsSignComponent(true);
        }
    }

    return (
        <div>
            {!isSignComponent && <div className="main-body">
                    {(role==='2' || role==='3') && 
                        <Grid container>
                            <Grid item xs={6}>
                                <a className="second-tab">{t("create_text12")}</a>
                            </Grid>
                            <Grid item xs={6}>
                                <Link to="/index" className="first-tab">{t("create_text13")}</Link>
                            </Grid>
                        </Grid>
                    }
                    {(role!=='2' && role!=='3') && 
                        <Grid container>
                            <Grid item xs={12}>
                                <Link to="/index" className="first-tab">{t("create_text14")}</Link>
                            </Grid>
                        </Grid>
                    }
                    <Grid container>
                        <Grid item className="upload-file-box" style={{width: "1030px", maxWidth:"90%"}}>
                            {file===null && <div className="file-box blink"
                                onDragEnter={handleDragDropEvent}
                                onDragOver={handleDragDropEvent}
                                onDrop={(e) => {
                                    handleDragDropEvent(e);
                                    setFiles(e, 'a');
                                }}
                                >
                                <div className="file-add" onClick={() => inputRef.current.click()}></div>
                                <p className="file-info">{t("create_text15")}</p>

                                {/* Hide the crappy looking default HTML input */}
                                <input
                                    ref={inputRef}
                                    type="file"
                                    multiple
                                    accept="application/pdf"
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                    setFiles(e, 'a');
                                    inputRef.current.value = null;
                                    }}/>
                            </div>
                            }
                            {file!==null && <div className="file-box"
                                onDragEnter={handleDragDropEvent}
                                onDragOver={handleDragDropEvent}
                                onDrop={(e) => {
                                    handleDragDropEvent(e);
                                    setFiles(e, 'a');
                                }}
                                >

                                <p className="file-box-name">{fileName}</p>
                                <p className="file-box-uploaded" onClick={() => inputRef.current.click()}>{t("create_text16")}</p>

                                {/* Hide the crappy looking default HTML input */}
                                <input
                                    ref={inputRef}
                                    type="file"
                                    multiple
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setFiles(e, 'a');
                                        inputRef.current.value = null;
                                    }}/>
                            </div>
                            }
                        </Grid>
                        <Grid item xs className="right-element">
                            {file===null && 
                                <div className='show-pdf' onClick={()=>showPdf()}>
                                    <span>{t("create_text17")}</span>
                                </div>
                            }
                            {file!==null && 
                                <div className='white-show-pdf' onClick={()=>showPdf()}>
                                    <span>{t("create_text17")}</span>
                                </div>
                            }
                        </Grid>
                    </Grid>
                    {validationMessage && <Grid container>
                        <div className="validation-error validation-document">{validationMessage}</div>
                    </Grid>
                    }
                    <Grid container className="padding-20">
                        <FormLabel>{t("create_text18")}</FormLabel>
                        <TextField required autoFocus {...register("name", { required: true })} /><br />
                    </Grid>
                    <Grid container className="border-grid">
                        <Grid item xs={6} className="border-first">
                            <Grid container className="box-signers">
                                <Grid item xs={12}>
                                    <h4>{t("create_text19")}</h4>
                                </Grid>
                                {myItems.map((row, index) => {
                                    return(<Grid item xs={12} className={`box-item ${"box-item"+index}`}>
                                        <Grid container>
                                            <Grid item xs={11}>
                                                <p>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}<br/>{row.email}</p>
                                            </Grid>
                                            <Grid item xs={1} className="right-element">
                                                <a className="x" onClick={()=>{removeMyClick(row)}}></a>
                                            </Grid>
                                        </Grid>
                                    </Grid>);
                                })}
                                <br/>
                                {!isAddMyItem && !isSearchMyItem && <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8}>
                                            <Button className="button-person width98" onClick={()=>{addMyClick()}}>{t("create_text20")}</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button className="button-person width98" onClick={()=>{searchMyClick()}}>{t("create_text21")}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                {isAddMyItem && <Grid item xs={12}>
                                        <Grid container className="add-person-box">
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={11}>
                                                        <h5>{t("create_text22")}</h5>
                                                    </Grid>
                                                    <Grid item xs={1} className="right-element">
                                                        <a className="x" onClick={()=>closeMyClick()}></a>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField autoFocus {...register("myEmail", { required: true })} placeholder={t("create_text23")}/>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <TextField {...register("myFirstName", { required: true })} placeholder={t("create_text24")}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField {...register("myLastName", { required: true })} placeholder={t("create_text25")}/>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <TextField {...register("myCompanyName", { required: true })} placeholder={t("create_text26")}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField {...register("myCompanyNumber", { required: true })} placeholder={t("create_text27")}/>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign:"right"}}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox {...register("mySave")} color='primary'/>
                                                    }
                                                    label={t("create_text28")}
                                                />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button className="button-person width98 blink" onClick={()=>addMyPerson()}>{t("create_text29")}</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {isSearchMyItem && <Grid item xs={12}>
                                        <Grid container className="add-search-box">
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4}>
                                                        <h5>{t("create_text30b")}</h5>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <i className="search"></i>
                                                        <TextField placeholder={t("create_text30")} className='search-input' {...register("mySearch",{onChange: (e) => mySearch(e.target.value)})}/>
                                                    </Grid>
                                                    <Grid item xs={1} className="right-element">
                                                        <a className="x" onClick={()=>closeMyClick()}></a>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {searchMyContacts.map((row, index) => {
                                                return(
                                                    <Grid item xs={12} key={index} className="add-search-box-item" onClick={()=>clickMyItem(row)}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <p>{row.firstName+' '+row.lastName+' - '+row.company+' - '+row.companyNumber+' - '+row.email}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>);
                                            })}
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p className="box-signers-radio-info">{t("create_text31b")}</p>
                                    </Grid>
                                    <Grid container>
                                        <RadioGroup className="radio" defaultValue="1" color="primary" onChange={(event)=>setSignMyOrder(event.target.value)}>
                                            <FormControlLabel color="primary" value="1" control={<Radio />} label={t("create_text31")} />
                                            <FormControlLabel color="primary" value="2" control={<Radio />} label={t("create_text32")} />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className="border-second">
                            <Grid container className="box-signers">
                                <Grid item xs={12}>
                                    <h4>{t("create_text32b")}</h4>
                                </Grid>
                                {theirItems.map((row, index) => {
                                    return(<Grid item xs={12} className={`box-item ${"box-item"+(index+4)}`}>
                                        <Grid container>
                                            <Grid item xs={11}>
                                                <p>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}<br/>{row.email}</p>
                                            </Grid>
                                            <Grid item xs={1} className="right-element">
                                                <a className="x" onClick={()=>{removeTheirClick(row)}}></a>
                                            </Grid>
                                        </Grid>
                                    </Grid>);
                                })}
                                {!isAddTheirItem && !isSearchTheirItem && <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8}>
                                            <Button className="button-person width98" onClick={()=>{addTheirClick()}}>{t("create_text33")}</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button className="button-person width98" onClick={()=>{searchTheirClick()}}>{t("create_text34")}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                {isAddTheirItem && <Grid item xs={12}>
                                        <Grid container className="add-person-box">
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={11}>
                                                        <h5>{t("create_text35b")}</h5>
                                                    </Grid>
                                                    <Grid item xs={1}  className="right-element">
                                                        <a className="x" onClick={()=>closeTheirClick()}></a>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField autoFocus {...register("theirEmail", { required: true })} placeholder={t("create_text35")}/>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <TextField {...register("theirFirstName", { required: true })} placeholder={t("create_text36")}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField {...register("theirLastName", { required: true })} placeholder={t("create_text37")}/>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <TextField {...register("theirCompanyName", { required: true })} placeholder={t("create_text38")}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField {...register("theirCompanyNumber", { required: true })} placeholder={t("create_text39")}/>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign:"right"}}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox {...register("theirSave")} color='primary'/>
                                                    }
                                                    label={t("create_text40")}
                                                />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button className="button-person width98 blink" onClick={()=>addTheirPerson()}>{t("create_text41")}</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }                        
                                {isSearchTheirItem && <Grid item xs={12}>
                                        <Grid container className="add-search-box">
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4}>
                                                        <h5>{t("create_text42")}</h5>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <i className="search"></i>
                                                        <TextField placeholder={t("create_text42b")} className='search-input' {...register("theirSearch",{onChange: (e) => theirSearch(e.target.value)})}/>
                                                    </Grid>
                                                    <Grid item xs={1} className="right-element">
                                                        <a className="x" onClick={()=>closeTheirClick()}></a>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {searchTheirContacts.map((row, index) => {
                                                return(
                                                    <Grid item xs={12} key={index} className="add-search-box-item" onClick={()=>clickTheirItem(row)}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <p>{row.firstName+' '+row.lastName+' - '+row.company+' - '+row.companyNumber+' - '+row.email}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>);
                                            })}
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p className="box-signers-radio-info">{t("create_text43")}</p>
                                    </Grid>
                                    <Grid container>
                                        <RadioGroup className="radio" defaultValue="1" onChange={(event)=>setSignTheirOrder(event.target.value)}>
                                            <FormControlLabel value="1" control={<Radio />} label={t("create_text44")} />
                                            <FormControlLabel value="2" control={<Radio />} label={t("create_text45")} />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <p className="file-info2">{t("create_text46")}</p>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item style={{width: "450px"}}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Button className={classButton1} onClick={()=>{setSignOrder(1);setClassButton1('button-sign-active');setClassButton2('button-sign');setClassButton3('button-sign')}}>{t("create_text47")}</Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button className={classButton2} onClick={()=>{setSignOrder(2);setClassButton1('button-sign');setClassButton2('button-sign-active');setClassButton3('button-sign')}}>{t("create_text48")}</Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button className={classButton3} onClick={()=>{setSignOrder(3);setClassButton1('button-sign');setClassButton2('button-sign');setClassButton3('button-sign-active')}}>{t("create_text49")}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center" className="border-top">
                        <Grid item style={{width:"280px"}}>
                            <Button className="button" onClick={()=>submitDocument()} fullWidth>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>{t("create_text50")}</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                    <br/>
                </div>
            }
            {isSignComponent && 
                <div>
                    <SignComponent myItems={myItems} theirItems={theirItems} setIsSignComponent={setIsSignComponent} model={model} file={file}></SignComponent>
                </div>
            }
        </div>
    );
}

export default CreateComponent;