import React from "react";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export class AuthVerify {
    constructor() {
        this.verifyUser = () =>{
            const user = localStorage.getItem("token");
            if (user && user!=='undefined') {
              const decodedJwt = parseJwt(user);
              if (decodedJwt.exp * 1000 < Date.now()) {
                  localStorage.setItem('token', '');
                  localStorage.setItem('fullName', '');
                  localStorage.setItem('role', '');
                  localStorage.setItem('email', '');
                  localStorage.setItem('avatar', '');
                  window.location.href = "/";
              }
              else
              {
                localStorage.setItem('documentId', '');
              } 
            }
            else
            {
              localStorage.setItem('token', '');
              localStorage.setItem('fullName', '');
              localStorage.setItem('role', '');
              localStorage.setItem('email', '');
              localStorage.setItem('avatar', '');
              window.location.href = "/";
            }
        }
    }
};

export const authVerify = new AuthVerify({});