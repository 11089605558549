import axios from 'axios';
import { handleResponse, handleError } from '../utilities/Response';
import { apiProvider } from '../utilities/Provider';

export class ApiUsers {
    constructor() {
        this.users = async () =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/user/getUsers`, {start:0, end: 999, page:1}, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.saveUser = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/user/add`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.remove = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/user/remove`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.removeMyAccount = async () =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/user/removeMyAccount`, null, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }
    }
}

export const apiUsers = new ApiUsers({});