import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'; 
import {apiLogin} from '../../services/api/accounts/Login';
import { useTranslation } from "react-i18next";

const ActivateComponent = () => {
    const { register, handleSubmit, reset } = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [errorType, setErrorType] = useState(0);
    const [confirmationToken, setConfirmationToken] = useState('');
    const { t, i18n, ready } = useTranslation();

    const onSubmit = async (data) => {
        if(data.password!=data.password2)
        {
            setErrorType(1);
            return;   
        }
        data.confirmationToken = confirmationToken;
        let result = await apiLogin.activate(data);
        if (result.Error) {
            setErrorType(2);
            return;
        }
        else {
            window.location.href = "/login?type=3";
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let token = params.get('confirmationToken');
        setConfirmationToken(token);
    }, [reset]);

    return (
        <Grid container className="center-vertical-item">
            <Container component="main" maxWidth="sm" className="login-container height100">
                <Link className="logo-small" to="/login"></Link>
                <Grid container direction="column" className="height100">
                    <Grid className="login-first-row">
                        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h1>{t("activation_text1")}</h1>
                                </Grid>
                                {errorType===1 && 
                                <Grid item xs={12}>
                                    <div className="validation-error">{t("activation_text6")}</div>
                                </Grid>
                                }
                                {errorType===2 && 
                                <Grid item xs={12}>
                                    <div className="validation-error">{t("activation_text7")}</div>
                                </Grid>
                                }
                                <Grid item xs={12}>
                                    <FormLabel>{t("activation_text2")}</FormLabel>
                                    <TextField type="password" required autoFocus {...register("password", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>{t("activation_text3")}</FormLabel>
                                    <TextField required type="password" {...register("password2", { required: true })} /><br />
                                </Grid>
                                <br/>
                                <Grid item xs={12}>
                                    <Button type="submit" className="button" fullWidth color="primary">{t("activation_text4")}</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid className="login-options">
                        <div className="space"></div>
                        <br/>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default ActivateComponent;